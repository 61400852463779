.events {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .event-card-img {
    border-radius: 1rem;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .action-btn {
    min-width: 100px;
  }

  .mini-profile {
    height: 40px;
    width: 40px;
    margin-left: 2rem;
  }

  @media screen and (max-width: 1200px) {
    .action-btn-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .action-btn-wrapper button {
      width: 50% !important;
    }

    .action-btn {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .event-card-img {
      height: 200px;
      width: 200px;
    }
  }
}

.profile-card {
  .image-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .image-wrapper .user-img {
    height: auto;
    object-fit: cover;
    width: 100%;
  }

  .edit-file-btn {
    background-color: transparent !important;
    color: #fff !important;
    position: absolute;
  }

  .chat-btn {
    background: var(--primary);
    border-radius: 50%;
    bottom: 10px;
    height: 64px;
    overflow: hidden;
    position: absolute;
    right: 10px;
    width: 64px;
  }

  .popup {
    background: #fff;
    border-radius: 1rem;
    list-style-type: none;
    min-height: 50px;
    min-width: 150px;
    padding: 1rem;
    position: absolute;
    right: -2px;
    top: -2px;
    z-index: 50;

    /* stylelint-disable max-nesting-depth */
    li:last-child {
      border-bottom: 0 !important;
    }

    li {
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      padding: 0.6rem 0;
      text-align: right;
    }
  }
  /* stylelint-enable max-nesting-depth */

  .more-btn {
    cursor: pointer;
    height: 28px;
    width: 4px;
  }
}
