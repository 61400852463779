.feed {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .feed-header {
    height: 7vh;
  }

  .font-not-so-grey {
    color: var(--not-so-grey) !important;
  }

  .mini-profile {
    height: 40px;
    width: 40px;
  }

  .upload-icon {
    height: 20px;
    width: 20px;
  }

  .post-friend-img {
    height: 32px;
    width: 32px;
  }

  .verified-icon {
    height: 15px;
    width: 15px;
  }

  .triple-dot-h {
    width: 25px;
  }

  .post-img {
    width: 100%;
    max-height: 80vh;
  }

  .post-action-icon {
    height: 18px;
    width: 18px;
  }

  .comment-profile-img {
    height: 32px;
    width: 32px;
  }

  .comment-input {
    background-color: #fafafb;
    width: 80%;
  }

  .divider {
    width: 90%;
  }

  .post-text {
    width: 90%;
  }

  .post-video video {
    width: 100%;
    max-height: 80vh;
  }

  .upload-img {
    height: 100px;
    margin-top: -40px;
    position: absolute;
    top: 0;
    width: 100px;
    z-index: 1;
  }

  .upload-card {
    padding-bottom: 10px;
    padding-top: 80px;
  }

  .view-post {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100%;
  }

  .feed-list {
    height: 70vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  @media screen and (max-width: 425px) {
    .feed-header {
      height: 10vh;
    }
  }
}

@media screen and (min-width: 768px) {
  .feed {
    .upload-camera-icon {
      right: 310px;
      top: 30px;
      z-index: 10;
    }
  }
}

@media screen and (min-width: 1024px) {
  .feed {
    .upload-camera-icon {
      right: 70px;
      top: 30px;
      z-index: 10;
    }
  }
}

@media screen and (min-width: 1200px) {
  .feed {
    .upload-camera-icon {
      right: 100px;
      top: 30px;
      z-index: 10;
    }
  }
}
